import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getLeadsContent = createAsyncThunk("/leads/content", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URLS}/trees/geteventdetail`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  console.log(response, "response");
  return response.data;
});

export const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    isLoading: false,
    leads: [],
  },
  reducers: {
    addNewLead: (state, action) => {
      let { newLeadObj } = action.payload;
      state.leads = [newLeadObj, ...state.leads];
    },

    deleteLead: (state, action) => {
      let { index } = action.payload;
      state.leads.splice(index, 1);
    },
  },

  extraReducers: {
    [getLeadsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getLeadsContent.fulfilled]: (state, action) => {
      state.leads = action.payload.data;
      state.isLoading = false;
    },
    [getLeadsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewLead, deleteLead } = leadsSlice.actions;

export default leadsSlice.reducer;
